.menuIcon {
  cursor: pointer;
  grid-column-start: main-start;
  fill: var(--color-text);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {width: 16px; height: 16px;
    will-change: transform;
  }
}