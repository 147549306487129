@use "node_modules/@ef-global/web-ui/scss/settings/mixins/breakpoints";

.globalHeader {
  padding: 4px 0;
  position: relative;
  z-index: 999;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.3s ease;
  transition-delay: 0.3s;

  @include breakpoints.breakpoint(m) {
    padding: 8px 0;
  }
}

.globalHeader:not(.active) {
  border-bottom-color: var(--color-divider);
  background-color: var(--global-header-bg, inherit);
}

.globalHeader.active {
  --color-text: var(--color-ink-black);
}

.globalHeader__item {
  display: flex;
  align-items: center;
}

.globalHeader__title {
  grid-column: main-start/main-end;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  z-index: -1;
}

.globalHeader__link {
  grid-column-end: main-end;
  @include breakpoints.breakpoint(s only) {
    display: none;
  }
  justify-content: flex-end;
}

.globalHeader__linkContent {
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.2s ease;

  &:hover {
    border-bottom-color: currentColor;
  }
}

.globalHeader__titleContent {
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  margin: 0;
  text-transform: uppercase;
  justify-self: center;
}